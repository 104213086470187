
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  ProductMasterSearchCriteriumModel } from "./productmasterSearchCriterium.model";
import { ProductValues } from '../../../../models/selectproductmanager/setproducts/productSelection_Type_Enum';
import { IArticleMasterInformation } from '../component/productmastgerSearchCriterium.model';
import { getSearchCriterionProductList } from './productmasterSearchCriterium.thunks';
const initialState: ProductMasterSearchCriteriumModel = {
    loadedData: {
       
    },
    command: {
       // getProductMasterTable: { status: "idle", canExecute: false },
       getProductMasterTableSearchCriterion : {status:"idle", canExecute:false}
    },
    data: {
        articleInformation: {
            articleTitle: '',
            id:0,
            size:20,
            page:1

        } , 
       availableTableTitles: [],
       productInformations: {

       content: {
                data: [],
                page: 0,
                pageCount: 0,
                pageSize: 0,
                totalCount:0
            },
            error: null,
            success:false
        
        },
       tablePaginationPageNumber:0
    },
   
    
}

export const productmasterSearchCriteriumCreateSlice = createSlice({
    name: 'getSearchCriterim',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data= initialState.data;
            state.command = initialState.command;
            state.loadedData = initialState.loadedData;
        },
        
        setArticleInformations: (state, action: PayloadAction<IArticleMasterInformation>) => {
            state.data.articleInformation = action.payload;
            
        },
        setProductValues: (state) => {

            state.data.availableTableTitles = ProductValues
            .filter((x) => x.value !== 0 && x.value !== 18 && x.value !== 19 && x.value !== 20)
            .sort((a, b) => (a.value === 21 ? -1 : b.value === 21 ? 1 : 0));
        },
        incrementTablePaginateNumber: (state) => {
            state.data.tablePaginationPageNumber += 1;
            state.data.articleInformation.page = state.data.tablePaginationPageNumber + 1;
        },
        decrementTablePaginateNumber: (state) => {

            if(state.data.tablePaginationPageNumber>0){
                state.data.tablePaginationPageNumber -= 1;
            }

            if (state.data.articleInformation.page > 1) {
                state.data.articleInformation.page = state.data.tablePaginationPageNumber + 1;
            }
        }
       
    },

    extraReducers: (builder) => {
        builder.addCase(getSearchCriterionProductList.pending, (state) => {
         
           state.command.getProductMasterTableSearchCriterion.status ='pending'
        }).addCase(getSearchCriterionProductList.rejected, (state, action) => {
            state.command.getProductMasterTableSearchCriterion.status ='error'
        }).addCase(getSearchCriterionProductList.fulfilled, (state, action) => {
             state.command.getProductMasterTableSearchCriterion.status ='success'
            state.data.productInformations  = action.payload.getData();
           state.data.articleInformation.page = ++state.data.productInformations.content.page;
            //state.data.productInformations.flatMap((x)=> state.data.articleInformation.page = x.page)
        });

        
    }
})

export const {
    resetState,
    setArticleInformations,
    setProductValues,
    incrementTablePaginateNumber,
    decrementTablePaginateNumber
    
}= productmasterSearchCriteriumCreateSlice.actions

export default productmasterSearchCriteriumCreateSlice.reducer