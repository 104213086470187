import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux_store";
import { handleResponseErrors } from "../../../../shared/networking/error_handling";
import ProductmasterService from "services/productmaster.service";
import { IProductInformationId } from "../component/productmastgerSearchCriterium.model";



export const getSearchCriterionProductList = createAsyncThunk(
    'articletunks/articletunks/getSearchCriterionProductList',
    async (_, { getState }) => {
        const state = getState() as RootState
        let productInformationId : IProductInformationId ={
            id: state.productmasterSearchCriterium.data.articleInformation.id,
            size: state.productmasterSearchCriterium.data.articleInformation.size,
            page: state.productmasterSearchCriterium.data.articleInformation.page
        }
        const response = await ProductmasterService.GetSearchCriterionProductInformation(productInformationId)
        handleResponseErrors(response, "getSearchCriterionProductList");
        return response;
    }
);