import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as style from '../../component/productmaster.style';
import { FlexBox, Left, LoadingIndicatorComponent, PrimaryButtonComponent, Right } from 'shared/shared';
import RoleBackIcon from '@material-ui/icons/NavigateBefore'
import { useNavigate } from "react-router";
import { TablePagination, Typography } from "@material-ui/core";
import { useAppSelector, useAppDispatch } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { useEffect } from "react";
import { decrementTablePaginateNumber, incrementTablePaginateNumber, resetState, setProductValues } from "../redux/productmasterSearchCriterium.slice";
import { getSearchCriterionProductList } from "../redux/productmasterSearchCriterium.thunks";

export const ProductmasterSearchCriteriumOverviewTableOverviewComponent = () => {
    const navigate = useNavigate();
    const state = useAppSelector((state) => state.productmasterSearchCriterium);
    const dispatch: AppDispatch = useAppDispatch();
  
    useEffect(() => {
        return () => {
            dispatch(resetState())
           
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(setProductValues())
        dispatch(getSearchCriterionProductList())
     
    },[dispatch]);


    if( state.command.getProductMasterTableSearchCriterion.status ==='pending'){
        return <LoadingIndicatorComponent />;
    }

    // function handleChangePage(): void {
    //     dispatch(incrementTablePaginateNumber())
    //     dispatch(getSearchCriterionProductList())
        
    // }

     // Handler for page change
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
      if (newPage > state.data.tablePaginationPageNumber) {
          dispatch(incrementTablePaginateNumber())
          console.log('Next button clicked');
      } else if (newPage < state.data.tablePaginationPageNumber) {
          dispatch(decrementTablePaginateNumber())
          console.log('Back button clicked');
      }
      dispatch(getSearchCriterionProductList());
   
  };

    return (<>
     <FlexBox>
                <Left>
                    <Typography variant="h4" >Artikelstamm - {state.data.articleInformation.articleTitle}</Typography>
                </Left>
                <Right>
                    <PrimaryButtonComponent onClick={() => navigate("/home/productmaster")}>
                        <RoleBackIcon /> Zurück
                    </PrimaryButtonComponent>
                 
                </Right>
            </FlexBox>

            <br/>

<TableContainer>
                <Table className="testtable" aria-label="massmask table">
                    <TableHead>
                        <TableRow>
                            
                            {state.data.availableTableTitles.map((x)=> 
                            <style.StyledHeaderCell align="left">{x.text}</style.StyledHeaderCell>
                            )}
                        </TableRow>
                    </TableHead>
                    {state.data.productInformations.content.data.map((e)=> 
                        <TableBody key={e.sku} >
                            <style.StyledTableRow key={e.sku} hover={true}>
                            <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.sku}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.mainProductLine}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.productLine}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.productType}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.compressionClass}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.productionType}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.color}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.additionalDesign}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.foot}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.shortened}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.fullyKnitted}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.extraWideCalf}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.compressivePantyTop}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.side}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.size}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.quantityUnit}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.gender}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='medium' >
                                    {e.singlePiece}
                                </style.StyledMenuTableCell>
                                </style.StyledTableRow>
                        </TableBody>
                    )}
                    
                </Table>
                <TablePagination
                                    rowsPerPageOptions={[state.data.productInformations.content.pageCount]}
                                    component="div"
                                    count={state.data.productInformations.content.totalCount}
                                    rowsPerPage={state.data.productInformations.content.pageSize}
                                    page={state.data.tablePaginationPageNumber}
                                    onPageChange={handleChangePage}
                                />
                                
            </TableContainer>
    
    
    </>); 
}