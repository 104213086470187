import { Route, Routes } from "react-router-dom";
import { ProductManagerComponent } from './preSelection/component/preselection.component';
import { HierarchyAssignmentComponent } from './hierarchyAssignment/component/hierarchyAssignment.component';
import { ProductmasterTableOverview } from './productmaster/component/productmasterOverview.component';
import { ProductmasterAddOverviewComponent } from './productmaster/Add/component/productmasterAddOverview.component';
import { ProductmanagerCountryTableUpdateComponent } from './productmaster/Update/component/productmasterUpdateOverview.component';
import { ProductmasterDeleteOverviewTableOverviewComponent } from './productmaster/Delete/component/productmasterDeleteOverview.component';
import {ProductmasterSearchCriteriumOverviewTableOverviewComponent} from './productmaster/SearchCriterium/component/productmasterSearchCriterium.component';
import { HomeComponent } from "../home/home.component";
import { ExportComponent } from "./administrator/component/export_Component";

export const AdminComponent = () => {
    return (

        <Routes>
            
            <Route element={<HomeComponent/> } path="/"></Route>
            <Route element={<ProductManagerComponent />} path="/productmanagerpreselection" />
            <Route element={< HierarchyAssignmentComponent />} path="/hierarchyassignment/:id" />
            <Route element={< ProductmasterTableOverview />} path="productmaster" />
            <Route element={< ProductmasterAddOverviewComponent />} path="productmaster/Add" />
            <Route element={< ProductmanagerCountryTableUpdateComponent />} path="productmaster/Update" />
            <Route element={< ProductmasterDeleteOverviewTableOverviewComponent />} path="productmaster/Delete" />
            <Route element={< ProductmasterSearchCriteriumOverviewTableOverviewComponent />} path="productmaster/SearchCriterium" />
            <Route element={< ExportComponent />} path="/export" />

        </Routes>)
}