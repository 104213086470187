import { configureStore } from '@reduxjs/toolkit';

import productmanager from '../admin/preSelection/redux/preselection.slice';
import hierarchyAssignmentCreateSlice from '../admin/hierarchyAssignment/redux/hierarchyAssignment.slice';
import productmasterAdd from '../admin/productmaster/Add/redux/productmasterAdd.slice';
import productmasterCreateSlice from '../admin/productmaster/redux/productmaster.slice';
import productmasterUpdate from '../admin/productmaster/Update/redux/productmasterUpdate.slice';
import productmasterDelete from '../admin/productmaster/Delete/redux/productmasterDelete.slice';
import exportCreateSlice from '../admin/administrator/redux/export.slice';
import productmasterSearchCriteriumCreateSlice from '../admin/productmaster/SearchCriterium/redux/productmasterSearchCriterium.slice';

export const reduxStore = configureStore({
    reducer: {
        selectproduct: productmanager,
        selectTreeView: hierarchyAssignmentCreateSlice,
        dataGrid: productmasterCreateSlice,
        dataGridEdit: productmasterAdd,
        dataGridUpdate: productmasterUpdate,
        dataGridDelete: productmasterDelete,
        workerQueue: exportCreateSlice,
        productmasterSearchCriterium: productmasterSearchCriteriumCreateSlice
        
    },
})

export type RootState = ReturnType<typeof reduxStore.getState>
export type AppDispatch = typeof reduxStore.dispatch
